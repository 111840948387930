body {
  overflow-x: hidden;
}
.notMobile {
  display: unset!important;
}
.inMobile {
  display: none!important;
}
.paragraph {
  margin-top: 0!important;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.custom-control-label {
  cursor: pointer;
}
table.max-content-always {
  width: max-content!important;
}
.fixe-height-modal > .modal-content {
  max-height: 48em;
  min-height: 40em;
}
.subtable:hover {
  background-color: transparent!important;
}
.subtable > td {
  background-color: rgba(233,236,239,.5)!important;
}
table.fixed thead th {
  position: sticky;
  z-index: 1;
  background: white;
}
table.fixed td {
  background: #fff;
  padding: 4px 5px;
}
table.fixed thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody td:first-child, table.fixed tbody th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
table.fixed tbody td {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  min-width: 100px;
}
ul {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
.container-bill {
  margin: 0 auto;
  margin-top: 48px;
  max-width: 780px;
}
.payment-link-grid .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--focus);
}
.bg-status-payment {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 350px;
}
.bg-status-payment-danger {
  background-image: linear-gradient(to bottom,
  rgba(255, 0, 0, 0.16),
  rgba(0, 255, 6, 0)
  );
}
.bg-status-payment-success {
  background-image: linear-gradient(to bottom,
  rgba(0, 255, 0, 0.16),
  rgba(0, 255, 6, 0)
  );
}
.data-box {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 16px;
  margin: 0;
  margin-top: 24px;
}
.data-box .row {
  width: 100%;
}
.data-box label, .data-box h1, .data-box h2, .data-box h3, .data-box h4, .data-box h5, .data-box h6 {
  margin: 0;
}
.title-box {
  position: absolute;
  top: -11px;
  left: 11px;
  background-color: #FFF;
  padding: 0 5px;
}
.list-box {
  margin: 1em 0 0 0;
  padding: 0;
  list-style: none;
}
.list-box li {
  margin-bottom: 5px;
}
.item-list-box-number {
  margin-right: 8px;
  text-align: right;
  width: 16px;
}
.item-list-payment-link {
  margin-left: 8px;
}
.item-list-box-number, .item-list-payment-link {
  display: inline-block;
}
.list-box li span {
  padding: 6px 7px;
  position: relative;
  margin-left: -8px;
  padding-right: 16px;
  vertical-align: bottom;
}
.list-box li span.is-payment-of-invoice {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.list-box .item-list-box-arrow {
  color: var(--primary);
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  right: -5px;
  background-color: rgb(255, 255, 255);
}
.data-box .payment-forms {
  margin: 2em 0;
}
.box-view-boleto {
  padding: 0 15px;
}
.box-security-website {
  background: url('https://files-nfservice-development.s3.ca-central-1.amazonaws.com/imagens/selo-ssl.png') no-repeat center;
  min-height: 144px;
  border-left: 1px solid #D8D8D8;;
}
.item-list-box-arrow {
  display: none;
}
.is-payment-of-invoice .item-list-box-arrow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 'auto';
  margin-bottom: 'auto';
  right: -5;
  background-color: '#FFF';
}
.float-barcode {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  position: absolute;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: -40px
}
.icon-link {
  cursor: pointer;
}
:focus {
  outline: none;
}
.link-primary {
  color: var(--primary);
  cursor: pointer;
}
.link-primary:hover {
  color: var(--focus);
}
.flex-space-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-space-between.grid div:first-child {
  max-width: 70%;
}
.flex-space-between.grid div:last-child {
  max-width: 30%;
}
svg {
  transition: transform .5s;
}
.rotate-180 {
  transform: rotate(180deg);
}
.image-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  margin-bottom: 16px;
}
.image-grid .main-image {
  grid-row: 1 / 3;
  grid-column: 1 / 3;
  align-self: center;
  width: 100%;
  height: 200px;
}
.image-grid .secondary-image {
  width: 100%;
  height: 95px;
  align-self: center;
  margin: auto;
}
.image-grid .payment-link-img-cover {
  height: 100%;
  object-fit: contain;
  width: 100%;
  background: no-repeat center;
  background-size: cover;
}
.hide {
  display: none;
}
.color-container {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  justify-content: left;
  grid-template-columns: repeat(6, auto);
}
.color-container-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px #5f5f5f;
}

.document-preview {
  position: relative;
}

.document-preview img {
  width: 250px;
  height: 250px;
}
.document-preview > .status {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
}
.page-title-heading {
  max-width: 70%;
}
td, th {
  vertical-align: inherit!important;
}
@media (max-width: 992px) {
  .notMobile {
    display: none!important;
  }
  .inMobile {
    display: unset!important;
  }
  ul#navAdaptive {
    display: grid!important;
  }
  table.max-content {
    width: max-content!important;
  }
  .fixe-height-modal > .modal-content {
    max-height: 45em;
    min-height: 40em;
  }
  .container-bill {
    margin-top: 0;
    width: 100vw;
  }
  .flex-space-between.grid div:first-child {
    max-width: 100%;
    width: 100%;
  }
  .flex-space-between.grid div:last-child {
    max-width: 100%;
    width: 100%;
  }
  .app-page-title .page-title-icon {
    display: none!important;
  }
}
@media screen and (max-width: 480px) {
  .image-grid {
    position: relative;
    display: grid;
    gap: unset;
    grid-template: none;
  }
  .image-grid .payment-link-img {
      height: 200px;
  }
  .image-grid .secondary-image {
    display: none;
  }
}
